import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CartService, CartItem } from '../services/cart.service';
import { ApiService } from '../services/api.service';

import { FormattedStorageData } from '../models/formatted-storage-data';

import { format_currency, format_consumption, format_co2, format_storage } from '../utils/formatter';
import {AgGridAngular} from "ag-grid-angular";

@Component({
    selector: 'app-cart-item',
    standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbTooltipModule,
    AgGridAngular
  ],
    templateUrl: './cart-item.component.html',
    styleUrl: './cart-item.component.scss'
})
export class CartItemComponent implements OnInit {
    @Input({ required: true }) item!: CartItem;

    format_currency = format_currency;
    format_consumption = format_consumption;
    format_co2 = format_co2;
    format_storage = format_storage;

    formatted_storage_data: FormattedStorageData[] = [];
    available_storage: FormattedStorageData[] = [];

    add_storage_shown: boolean = false;

    storage_volume_input_value!: number;
    storage_type_selected!: FormattedStorageData;

    constructor (
        public cartService: CartService,
        public apiService: ApiService,
    ) {}

    ngOnInit(): void {
        this.apiService.storage_data.subscribe(data => {
            if (!data) return;
            this.formatted_storage_data = data;
            this.update_available_storage();
        })
    }

    update_available_storage(): void {
        this.available_storage = this.formatted_storage_data.filter(storage => {
            return (
              storage.provider == this.item.vm.provider
              && storage.region == this.item.vm.region
            )
        })
        this.storage_type_selected = this.available_storage[0];
        this.checkInputConstraints();
    }

    checkInputConstraints = () => {
      if (this.storage_type_selected.volumeSizeMin == this.storage_type_selected.volumeSizeMax) {
        this.storage_volume_input_value = this.storage_type_selected.volumeSizeMin;
      }
    }

    resetForm = () => {
      // @ts-ignore
      this.storage_volume_input_value = undefined;
      this.update_available_storage();
    }

    add_storage(): void {
        if (!this.storage_type_selected) return;
        // little check
        if (this.storage_type_selected.provider != this.item.vm.provider
            || this.storage_type_selected.region != this.item.vm.region
            // || this.storage_type_selected.volumeSizeMin > this.storage_volume_input_value
            // || this.storage_type_selected.volumeSizeMax < this.storage_volume_input_value
        ) return;
        let selected_volume_size = Math.max(this.storage_volume_input_value, this.storage_type_selected.volumeSizeMin);

        if ( this.storage_type_selected.volumeSizeMin == this.storage_type_selected.volumeSizeMax) {
          selected_volume_size = this.storage_type_selected.volumeSizeMax
        }

        this.cartService.add_storage_to_vm(this.item.id, this.storage_type_selected, selected_volume_size);
        this.add_storage_shown = false;
    }
}
