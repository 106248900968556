import { Injectable } from '@angular/core';
import {
    TextFilterModel,
    NumberFilterModel,
} from 'ag-grid-community';
import { ICombinedSimpleModel } from 'ag-grid-community';

interface StorageGridFilters {
    region?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    provider?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    name?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    volume?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    iops?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    iopsPerGB?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    maxIopsPerVolume?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    maxThroughputPerVolume?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    provisionedIops?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    throughputPerIO?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    volumeSizeMax?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    volumeSizeMin?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
}

export interface StorageGridFiltersParams {
    region?: string;
    provider?: string;
    name?: string;
    volume?: string;
    iops?: string;
    iopsPerGB?: string;
    maxIopsPerVolume?: string;
    maxThroughputPerVolume?: string;
    provisionedIops?: string;
    throughputPerIO?: string;
    volumeSizeMax?: string;
    volumeSizeMin?: string;
}

@Injectable({
    providedIn: 'root'
})
export class StorageGridService {
    filters!: StorageGridFilters;

    constructor() {}

    persistFilters(filters: StorageGridFilters) {
        this.filters = filters;
    }

    getFiltersFromQueryParams(params: StorageGridFiltersParams): StorageGridFilters | null {
        if (!params || !Object.keys(params).length) {
            return null;
        }
        return {
            region: params.region ? JSON.parse(decodeURIComponent(params.region)) : null,
            provider: params.provider ? JSON.parse(decodeURIComponent(params.provider)) : null,
            name: params.name ? JSON.parse(decodeURIComponent(params.name)) : null,
            volume: params.volume ? JSON.parse(decodeURIComponent(params.volume)) : null,
            iops: params.iops ? JSON.parse(decodeURIComponent(params.iops)) : null,
            iopsPerGB: params.iopsPerGB ? JSON.parse(decodeURIComponent(params.iopsPerGB)) : null,
            maxIopsPerVolume: params.maxIopsPerVolume ? JSON.parse(decodeURIComponent(params.maxIopsPerVolume)) : null,
            maxThroughputPerVolume: params.maxThroughputPerVolume ? JSON.parse(decodeURIComponent(params.maxThroughputPerVolume)) : null,
            provisionedIops: params.provisionedIops ? JSON.parse(decodeURIComponent(params.provisionedIops)) : null,
            throughputPerIO: params.throughputPerIO ? JSON.parse(decodeURIComponent(params.throughputPerIO)) : null,
            volumeSizeMax: params.volumeSizeMax ? JSON.parse(decodeURIComponent(params.volumeSizeMax)) : null,
            volumeSizeMin: params.volumeSizeMin ? JSON.parse(decodeURIComponent(params.volumeSizeMin)) : null,
        };
    }

    getQueryParamsFromFilters(): StorageGridFiltersParams {
        if (!this.filters || !Object.keys(this.filters).length) {
            return {
                region: undefined,
                provider: undefined,
                name: undefined,
                volume: undefined,
                iops: undefined,
                iopsPerGB: undefined,
                maxIopsPerVolume: undefined,
                maxThroughputPerVolume: undefined,
                provisionedIops: undefined,
                throughputPerIO: undefined,
                volumeSizeMax: undefined,
                volumeSizeMin: undefined,
            };
        }

        return <StorageGridFiltersParams> {
            region: encodeURIComponent(JSON.stringify(this.filters.region || null)),
            provider: encodeURIComponent(JSON.stringify(this.filters.provider || null)),
            name: encodeURIComponent(JSON.stringify(this.filters.name || null)),
            volume: encodeURIComponent(JSON.stringify(this.filters.volume || null)),
            iops: encodeURIComponent(JSON.stringify(this.filters.iops || null)),
            iopsPerGB: encodeURIComponent(JSON.stringify(this.filters.iopsPerGB || null)),
            maxIopsPerVolume: encodeURIComponent(JSON.stringify(this.filters.maxIopsPerVolume || null)),
            maxThroughputPerVolume: encodeURIComponent(JSON.stringify(this.filters.maxThroughputPerVolume || null)),
            provisionedIops: encodeURIComponent(JSON.stringify(this.filters.provisionedIops || null)),
            throughputPerIO: encodeURIComponent(JSON.stringify(this.filters.throughputPerIO || null)),
            volumeSizeMax: encodeURIComponent(JSON.stringify(this.filters.volumeSizeMax || null)),
            volumeSizeMin: encodeURIComponent(JSON.stringify(this.filters.volumeSizeMin || null)),
        };
    }
}
