import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-cell-renderer',
  standalone: true,
  imports: [],
  templateUrl: './button-cell-renderer.component.html',
  styleUrl: './button-cell-renderer.component.scss'
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick($event: any) {
    this.params.onClick(this.params.node.data);
  }
}
