import { Component, inject, TemplateRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../services/api.service';
import { FormattedCarbonEquivalents } from '../models/carbon-equivalents';

import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-carbon-widget',
    standalone: true,
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule
  ],
    templateUrl: './carbon-widget.component.html',
    styleUrl: './carbon-widget.component.scss'
})
export class CarbonWidgetComponent implements OnInit {
    @Input() slug!: string;
    @Input() value!: number;

    availableCarbonEquivalents: {slug: string, name: string}[] = [];

    carbon_equivalents!: FormattedCarbonEquivalents;

    private modalService = inject(NgbModal);
    closeResult = '';

    constructor(
        public apiService: ApiService
    ) { }

    open(content: TemplateRef<any>) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }

    private getDismissReason(reason: any): string {
      switch (reason) {
        case ModalDismissReasons.ESC:
          return 'by pressing ESC';
        case ModalDismissReasons.BACKDROP_CLICK:
          return 'by clicking on a backdrop';
        default:
          return `with: ${reason}`;
      }
    }

    ngOnInit(): void {
        this.apiService.carbon_equivalents.subscribe(data => {
            if (!data) return;
            this.carbon_equivalents = data;
            this.availableCarbonEquivalents = Object
              .entries(this.carbon_equivalents)
              .map(([key, value]) => Object({slug: key, name: value.name }));
        })
    }
}
