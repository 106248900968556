import { formatNumber, formatCurrency } from '@angular/common';

export function format_number(input: number): string {
    return input ? formatNumber(input, 'en-US', '1.0-10') : 'N/A';
}

export function format_currency(input: number): string {
    return input ? formatCurrency(input, 'en-US', '$', '$', '1.2-4') : 'N/A';
}

export function format_storage(input: number): string {
    if (!input) return 'N/A';
    const units = ['GiB', 'TiB', 'PiB', 'EiB'];
    let unitIndex = 0;
    let size = input;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${formatNumber(size, 'en-US', '1.0-2')} ${units[unitIndex]}`;
}

export function format_consumption(input: number): string {
    if (!input) return 'N/A';
    const units = ['Wh', 'kWh', 'MWh', 'GWh', 'TWh'];
    let unitIndex = 0;
    let value = input;
    while (value >= 1000 && unitIndex < units.length - 1) {
        value /= 1000;
        unitIndex++;
    }
    return `${formatNumber(value, 'en-US', '1.0-2')} ${units[unitIndex]}`;
}

export function format_co2(input: number): string {
    if (!input) return 'N/A';
    const units = ['mg CO2e', 'g CO2e', 'kg CO2e', 't CO2e', 'kt CO2e', 'Mt CO2e'];
    let unitIndex = 0;
    let value = input * 1_000_000;
    while (value >= 1000 && unitIndex < units.length - 1) {
        value /= 1000;
        unitIndex++;
    }
    return `${formatNumber(value, 'en-US', '1.0-2')} ${units[unitIndex]}`;
}

export function format_throughput(input: number): string {
    if (!input) return 'N/A';
    const units = ['kiB/s', 'MiB/s', 'GiB/s', 'TiB/s'];
    let unitIndex = 0;
    let size = input * 1000;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${formatNumber(size, 'en-US', '1.0-2')} ${units[unitIndex]}`;
}