@if(!hideHeader) {
  <div class="selectors" (click)="handleClick()">
    <div>
      <label for="deltatime">Time frame</label>
      <select name="deltatime" class="form-select" id="deltatime" [(ngModel)]="apiService.deltatime" (change)="onDeltaTimeChange()">
        <option value="1">Hourly</option>
        <option value="24">Daily</option>
        <option value="168">Weekly</option>
        <option value="720">Monthly</option>
        <option value="8760">Annually</option>
      </select>
    </div>
    <div>
      <label for="strange_prices">Carbon Emission available</label>
      <input type="checkbox" id="only_with_green_data_available" name="strange_prices" [(ngModel)]="only_with_green_data_available" (change)="check_only_with_green_data_available()">
    </div>
    @if (show_debug) {
      <div>
        <label for="strange_prices">Display only strange prices</label>
        <input type="checkbox" id="strange_prices" name="strange_prices" [(ngModel)]="strange_prices" (change)="check_strange_prices()">
      </div>
      <div>
        <label for="chart_shown">Show chart</label>
        <input type="checkbox" id="chart_shown" name="chart_shown" [(ngModel)]="chart_shown">
      </div>
    }
    <div>
      <button class="btn btn-sm btn-danger" (click)="clearAllFilters()">Clear filters</button>
    </div>
    <div>
      <span style="font-size: 1.7em;">
        <b>ECLYO</b> : cloud pricing & environnemental impact solution
      </span>
    </div>

    <div style="position: absolute; right: 15px;">
      <a class="icon-link icon-link-hover" href="https://youtu.be/uK02i1I2aMg" target="_blank">
        <i class="bi bi-youtube"></i>
        Youtube tutorial
      </a>
    </div>
  </div>
}
<div class="grid_div">
    <ag-grid-angular
        #agGrid
        [rowData]="formatted_compute_data"
        [columnDefs]="computeColDefs"
        [defaultColDef]="defaultColDef"
        [autoSizeStrategy]="autoSizeStrategy"
        (filterChanged)="filterChanged()"
        [pagination]=true
        class="ag-theme-quartz"
        [style.height]="maxHeight != -1 ? maxHeight - 10 + 'px' : 100+'%'">
    </ag-grid-angular>
</div>
@if (chart_shown) {
    <div class="selectors">
        <div>
            <label for="x_axis">X axis</label>
            <select name="x_axis" id="x_axis" [(ngModel)]="chart_axis_x" (change)="update_chart()">
                <option value="vcpu">vCPUs</option>
                <option value="memory">Memory</option>
                <option value="od">On demand price</option>
                <option value="1yr">1 Year price</option>
                <option value="3yr">3 Year price</option>
                <option value="consumption_min">Consumption min</option>
                <option value="consumption_max">Consumption max</option>
                <option value="cpu_consumption_min">CPU consumption min</option>
                <option value="cpu_consumption_max">CPU consumption max</option>
                <option value="memory_consumption">Memory consumption</option>
                <option value="storage_consumption">Storage consumption</option>
                <option value="emission_max">Max emissions</option>
                <option value="emission_min">Min emissions</option>
                <option value="grey_emission">Grey emissions</option>
                <option value="cpu_co2_min">CPU CO2 min</option>
                <option value="cpu_co2_max">CPU CO2 max</option>
                <option value="memory_co2">Memory CO2</option>
                <option value="storage_co2">Storage CO2</option>
            </select>
        </div>
        <div>
            <label for="log_scale_x">Logarithmic X axis</label>
            <input type="checkbox" id="log_scale_x" name="log_scale_x" [(ngModel)]="chart_x_log" (change)="update_chart()">
        </div>
        <div>
            <label for="y_axis">Y axis</label>
            <select name="y_axis" id="y_axis" [(ngModel)]="chart_axis_y" (change)="update_chart()">
                <option value="vcpu">vCPUs</option>
                <option value="memory">Memory</option>
                <option value="od">On demand price</option>
                <option value="1yr">1 Year price</option>
                <option value="3yr">3 Year price</option>
                <option value="consumption_min">Consumption min</option>
                <option value="consumption_max">Consumption max</option>
                <option value="cpu_consumption_min">CPU consumption min</option>
                <option value="cpu_consumption_max">CPU consumption max</option>
                <option value="memory_consumption">Memory consumption</option>
                <option value="storage_consumption">Storage consumption</option>
                <option value="emission_max">Max emissions</option>
                <option value="emission_min">Min emissions</option>
                <option value="grey_emission">Grey emissions</option>
                <option value="cpu_co2_min">CPU CO2 min</option>
                <option value="cpu_co2_max">CPU CO2 max</option>
                <option value="memory_co2">Memory CO2</option>
                <option value="storage_co2">Storage CO2</option>
            </select>
        </div>
        <div>
            <label for="log_scale_y">Logarithmic Y axis</label>
            <input type="checkbox" id="log_scale_y" name="log_scale_y" [(ngModel)]="chart_y_log" (change)="update_chart()">
        </div>
    </div>
    <div class="chart_div">
        <ag-charts-angular
            #agChart
            style="height: 100%;"
            [options]="chartOptions">
        </ag-charts-angular>
    </div>
}
