import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { FormattedComputeData } from '../models/formatted-compute-data';
import { CartItem } from './cart.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() { }

    exportExcel(data: CartItem[]): void {
        const workbook = new ExcelJS.Workbook();
        const compute_worksheet = workbook.addWorksheet();
        const storage_worksheet = workbook.addWorksheet();
        let id = 1;

        const compute_headers = ['ID', 'InstanceType', 'Provider', 'Region', 'On Demand Price', '1 Year Reservation Price', '3 Year Reservation Price', 'vCPU', 'Memory', 'Min Consumption', 'Max Consumption', 'Min Emission', 'Max Consumption', 'Quantity'];
        compute_worksheet.addRow(compute_headers);
        const storage_headers = ['ID', 'Type', 'Provider', 'Price', 'Volume'];
        storage_worksheet.addRow(storage_headers);

        data.forEach((item) => {
            const compute_row: any = [];
            compute_row.push(
                id,
                item.vm.instanceType,
                item.vm.provider,
                item.vm.region,
                item.vm.od ? item.vm.od : 0,
                item.vm['1yr'] ? item.vm['1yr'] : 0,
                item.vm['3yr'] ? item.vm['3yr'] : 0,
                item.vm.vcpu,
                item.vm.memory,
                item.vm.consumption_min,
                item.vm.consumption_max,
                item.vm.emission_min,
                item.vm.emission_max,
                item.quantity
            )
            compute_worksheet.addRow(compute_row);
            item.storage.forEach((storage) => {
                const storage_row: any = [];
                storage_row.push(
                    id,
                    storage.storage_type.name,
                    storage.storage_type.provider,
                    storage.storage_type.volume,
                    storage.volume
                )
                storage_worksheet.addRow(storage_row);
            })
            id++;
        });


        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${"exported_data"}.xlsx`);
        });
    }
}
