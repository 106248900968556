import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';

import { map, combineLatestWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiService } from '../services/api.service';
import { CartService, StorageItem } from '../services/cart.service';

import { FormattedComputeData } from '../models/formatted-compute-data';
import { FormattedStorageData } from '../models/formatted-storage-data';

@Component({
    selector: 'app-vm-wizard',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatCardModule,
        MatRadioModule,
    ],
    templateUrl: './vm-wizard.component.html',
    styleUrl: './vm-wizard.component.scss'
})
export class VmWizardComponent implements OnInit {
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;

    formatted_compute_data!: FormattedComputeData[];
    formatted_storage_data!: FormattedStorageData[];

    operatingSystems$: Observable<string[]>;
    matchingVMs$: Observable<FormattedComputeData[]>;

    selectedVM!: FormattedComputeData | null;
    availableStorage: FormattedStorageData[] = [];
    selectedDisks: StorageItem[] = [];

    constructor(
        private apiService: ApiService,
        public cartService: CartService,
        private _formBuilder: FormBuilder
    ) {
        this.firstFormGroup = this._formBuilder.group({
            cpu: ['', Validators.required],
            memory: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            os: ['', Validators.required]
        });
        this.thirdFormGroup = this._formBuilder.group({
            selectedVM: [null, Validators.required]
        });
        this.fourthFormGroup = this._formBuilder.group({
            addStorage: ['no', Validators.required],
            storageVolume: [''],
            storageType: ['']
        });

        this.operatingSystems$ = this.firstFormGroup.valueChanges.pipe(
            map(value => this.filterOperatingSystems(value.cpu, value.memory * 1024))
        );

        this.matchingVMs$ = this.firstFormGroup.valueChanges.pipe(
            combineLatestWith(this.secondFormGroup.valueChanges),
            map(([firstFormValues, secondFormValues]) => this.filterVMs(firstFormValues.cpu, firstFormValues.memory * 1024, secondFormValues.os))
        );
    }

    ngOnInit(): void {
        this.apiService.compute_data.subscribe(data => {
            if (!data) return;
            this.formatted_compute_data = data;
        });
        this.apiService.storage_data.subscribe(data => {
            if (!data) return;
            this.formatted_storage_data = data;
        });
    }

    filterOperatingSystems(cpu: number, memory: number): string[] {
        const matchingVMs = this.formatted_compute_data.filter(vm => vm.vcpu >= cpu && vm.memory >= memory);
        const osSet = new Set<string>();
        matchingVMs.forEach(vm => osSet.add(vm.os));
        return Array.from(osSet);
    }

    filterVMs(cpu: number, memory: number, os: string): FormattedComputeData[] {
        return this.formatted_compute_data
            .filter(vm => vm.vcpu >= cpu && vm.memory >= memory && vm.os === os)
            .sort((a, b) => (a.od && b.od ? a.od - b.od : 0))
            .slice(0, 10);
    }

    selectVM(vm: FormattedComputeData) {
        this.selectedVM = vm;
        this.thirdFormGroup.patchValue({ selectedVM: vm });
    }

    updateAvailableStorage() {
        const volume = this.fourthFormGroup.get('storageVolume')?.value;
        if (!volume) return;
        this.availableStorage = this.formatted_storage_data.filter(storage => {
            return (
                storage.provider === this.selectedVM?.provider &&
                storage.region === this.selectedVM?.region &&
                storage.volumeSizeMin <= volume &&
                storage.volumeSizeMax >= volume
            );
        });
    }

    addStorage() {
        const storageType = this.fourthFormGroup.get('storageType')?.value;
        const storageVolume = this.fourthFormGroup.get('storageVolume')?.value;
        if (storageType && storageVolume) {
            this.selectedDisks.push({
                id: crypto.randomUUID(),
                storage_type: storageType,
                volume: storageVolume,
            });
        }
    }

    removeDisk(id: string) {
        this.selectedDisks = this.selectedDisks.filter(d => d.id !== id);
    }

    addVmToCart() {
        if (this.selectedVM) {
            this.cartService.add_vm_to_cart(this.selectedVM, this.selectedDisks);
        }
    }

    resetStepper() {
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.fourthFormGroup.reset();
        this.selectedVM = null;
        this.selectedDisks = [];
    }
}
