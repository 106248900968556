<main>
    <section class="mosaic">
        <div>
            <h1>About ECLYO : cloud pricing & environnemental impact solution</h1>
            <p>
              ECLYO is an innovative comparison and simulation tool designed to provide you with detailed insights into
              the prices and carbon emissions of virtual machines offered by major cloud providers such as AWS, GCP,
              Azure. This tool allows you to evaluate and compare costs as well as the environmental impact of different
              options, helping you make informed decisions when migrating your on-premises infrastructure to the public
              cloud. At a glance, discover the instance prices and their carbon emissions to optimize both your budget
              and your ecological footprint. Prices are based on public prices published by cloud providers, and are
              updated on average once a week. To assess the carbon impact, the solution uses the Cloud Carbon footprint
              methodology.
            </p>
        </div>
        <div>
            <img src="CloudPricing.png" alt="">
        </div>
    </section>

    <section class="mosaic">
        <div>
            <img src="Operations-manager-VMware3-1024x853-1.jpeg" alt="">
        </div>
        <div>
            <h1>About EasyVirt : Experts in measuring and optimising the environmental footprint of IT since 2011</h1>
            <p>
              EasyVirt is a company specializing in IT infrastructure management and optimization, with a strong focus
              on Green IT solutions. The company offers software solutions that help businesses enhance their IT
              efficiency while reducing energy consumption and environmental impact, particularly in virtualization
              and cloud environments. EasyVirt’s solutions enable organizations to improve resource utilization, cut
              costs, and contribute to sustainability by minimizing their carbon footprint.

            </p>
        </div>
    </section>

    <section>
        <h1>EasyVirt Tools</h1>
        <div class="tools_list">
            <div class="tool">
                <img src="DC-Scope.png" alt="">
                <h2>DC Scope®</h2>
                <p class="badges">
                    <span class="badge">CloudOps</span>
                    <span class="badge">FinOps</span>
                    <span class="badge">GreenOps</span><br>
                    <span class="badge">Capacity Planning</span>
                    <span class="badge">AWS</span>
                    <span class="badge">Azure</span>
                </p>
                <p>
                    DC Scope® allows you to manage your vSphere infrastructure with ease. This tool improves visibility into your data center,
                    provides key data to enhance server efficiency, and enriches decision-making by offering real information on the behavior
                    of your virtual machines. With DC Scope®, you can track the costs of virtual machines, servers, and clusters, helping you
                    understand and optimize the allocated, used, and wasted costs.
                </p>
                <a href="https://www.easyvirt.com/dc-scope-gerer-votre-infrastructure-vsphere/">Learn More</a>
            </div>
            <div class="tool">
                <img src="DC-NetScope.png" alt="">
                <h2>DC NetScope®</h2>
                <p class="badges">
                    <span class="badge">Network flows</span>
                    <span class="badge">Dependencies</span>
                    <span class="badge">Protocols</span><br>
                    <span class="badge">Ports</span>
                    <span class="badge">Topology</span>
                </p>
                <p>
                    DC NetScope® provides detailed information on the different network layers within VMware infrastructures. This solution
                    offers operational and interactive views of network flows generated by virtual machines, displaying metrics, ports, and
                    protocols for each flow. DC NetScope® is essential for those seeking an in-depth understanding and optimized management of
                    their network.
                </p>
                <a href="https://www.easyvirt.com/dc-netscope-network-monitoring-tool/">Learn More</a>
            </div>
            <div class="tool">
                <img src="CO2-Scope.png" alt="">
                <h2>CO2Scope®</h2>
                <p class="badges">
                    <span class="badge">Embodied energy </span>
                    <span class="badge">Power consumption</span><br>
                    <span class="badge">Carbon footprint </span>
                    <span class="badge">Data center</span>
                    <span class="badge">Cloud</span>
                    <span class="badge">KPIs</span>
                </p>
                <p>
                    CO2Scope® allows for precise measurement of the actual consumption and CO2 emissions of IT services, based on the real
                    consumption of the IT infrastructure. Unlike estimates, CO2Scope® provides accurate data, enabling you to monitor and
                    reduce the carbon footprint of your IT operations effectively.
                </p>
                <a href="https://www.easyvirt.com/co2scope-reduction-des-emissions-de-co2/">Learn More</a>
            </div>
        </div>
    </section>
    <section class="disclaimers">
        <h1>Disclaimer</h1>
        <p>
            This application is for informational purposes only.<br>
            The data provided are estimates and should be verified with actual provider costs and carbon emissions data.
        </p>
        <h1>Sources</h1>
        <ul>
            <li><a href="https://www.cloudcarbonfootprint.org/">Cloud Carbon Footprint</a></li>
            <li><a href="https://aws.amazon.com/">AWS</a></li>
            <li><a href="https://cloud.google.com/">Google Cloud Platform</a></li>
            <li><a href="https://azure.microsoft.com/">Microsoft Azure</a></li>
            <li><a href="https://www.ovhcloud.com/">OVH Cloud</a></li>
        </ul>
    </section>
    <!-- <div>
        <img src="flyer_dc_scope.png" alt="">
        <img src="flyer_co2_scope.png" alt="">
    </div> -->
</main>
