<mat-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup" label="Hardware config">
        <form [formGroup]="firstFormGroup">
            <div>
                <mat-form-field>
                    <mat-label>vCPU amount</mat-label>
                    <input matInput type="number" placeholder="2" formControlName="cpu" required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Memory (GiB)</mat-label>
                    <input matInput type="number" placeholder="8" formControlName="memory" required>
                </mat-form-field>
            </div>
            <div>
                <button mat-button matStepperNext>NEXT</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" label="OS selection">
        <form [formGroup]="secondFormGroup">
            <mat-form-field>
                <mat-label>Select your OS</mat-label>
                <mat-select formControlName="os" required>
                    <mat-option *ngFor="let os of operatingSystems$ | async" [value]="os">{{os}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-button matStepperPrevious>PREVIOUS</button>
                <button mat-button matStepperNext>NEXT</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" label="Select your VM">
        <form [formGroup]="thirdFormGroup">
            <div class="cards-container">
                <div *ngFor="let vm of matchingVMs$ | async">
                    <mat-card appearance="outlined">
                        <mat-card-header>
                            <mat-card-title>{{ vm.instanceType }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>Provider : {{ vm.provider }}</p>
                            <p>Region : {{ vm.region }}</p>
                            <p>OS : {{ vm.os }}</p>
                            <p>vCPU : {{ vm.vcpu }}</p>
                            <p>Memory : {{ vm.memory }} MiB</p>
                            <p>Price (OD) : {{ vm.od | currency }}</p>
                            <p>Price (1yr) : {{ vm['1yr'] | currency }}</p>
                            <p>Price (3yr) : {{ vm['3yr'] | currency }}</p>
                            <p *ngIf="vm.consumption_min && vm.consumption_max">
                                Average Consommation (Wh/h): {{ (vm.consumption_min + vm.consumption_max) / 2 | number: '0.0-2' }}
                            </p>
                            <p *ngIf="vm.emission_min && vm.emission_max">
                                Average Emission (kg/h): {{ (vm.emission_min + vm.emission_max) / 2 * 1000 | number: '0.0-2' }}
                            </p>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button (click)="selectVM(vm)">SELECT</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
            <div>
                <button mat-button matStepperPrevious>PREVIOUS</button>
                <button mat-button matStepperNext [disabled]="!selectedVM">NEXT</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="fourthFormGroup" label="Add storage">
        <form [formGroup]="fourthFormGroup">
            <p>Would you like to add a storage disk ?</p>
            <mat-radio-group formControlName="addStorage">
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourthFormGroup.get('addStorage')?.value === 'yes'">
                <div>
                    <mat-form-field>
                        <mat-label>Volume (GiB)</mat-label>
                        <input matInput type="number" formControlName="storageVolume" (change)="updateAvailableStorage()" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Storage type</mat-label>
                        <mat-select formControlName="storageType" required>
                            <mat-option *ngFor="let storage of availableStorage" [value]="storage">{{storage.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button mat-button (click)="addStorage()">ADD</button>
                <div>
                    <ul>
                        <li *ngFor="let disk of selectedDisks">
                            <p>{{ disk.storage_type.name }}: {{ disk.volume }} GiB</p>
                            <button mat-button (click)="removeDisk(disk.id)">DELETE</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <button mat-button matStepperPrevious>PREVIOUS</button>
                <button mat-button matStepperNext (click)="addVmToCart(); resetStepper()">FINISH</button>
            </div>
        </form>
    </mat-step>

    <mat-step label="End">
        <p>Your virtual machine was successfully added.</p>
    </mat-step>
</mat-stepper>
