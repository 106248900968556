<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Carbon widget setting</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <label for="carbonEquivalent">Carbon equivalent</label>
        <div class="input-group">
          <select name="slug" id="carbonEquivalent" class="form-select" aria-label="Default select example" [(ngModel)]="slug">
            @for (carbonEquivalent of availableCarbonEquivalents; track carbonEquivalent) {
              <option [ngValue]="carbonEquivalent.slug">{{ carbonEquivalent.name }}</option>
            }
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<main style="position: relative">
    <div style="position: absolute; top: 5px; right: 5px; z-index: 10000; cursor: pointer;" (click)="open(content)">
      <i class="bi bi-gear"></i>
    </div>
    <div class="left">
        <a href="https://impactco2.fr/comparateur?value={{value.toFixed(0)}}" class="logo"
            target="_blank" rel="noreferrer noopener" aria-label="Logo Impact CO2"
            title="Lien externe : accéder au comparateur sur le site Impact CO2">
            <svg xmlns="http://www.w3.org/2000/svg"
                width="30" height="48" viewBox="0 0 30 48" fill="none">
                <path d="M6.31881 0C2.83399 0 0 2.83399 0 6.31881V23.0668C0 26.5505 2.83399 29.3856 6.31881 29.3856H23.0668C26.5505 29.3856 29.3856 26.5516 29.3856 23.0668V6.31881C29.3856 2.83514 26.5516 0 23.0668 0H6.31881ZM1.83653 6.31881C1.83653 3.84409 3.84294 1.83653 6.31881 1.83653H8.79353C9.20331 1.83653 9.55569 2.12234 9.64408 2.52179C10.0757 4.48228 9.49371 6.58052 8.03711 8.03597C6.58052 9.49256 4.48343 10.0745 2.52293 9.64293C2.12349 9.55455 1.83768 9.20216 1.83768 8.79353V6.31881H1.83653ZM17.2117 27.5479H6.31881C3.84409 27.5479 1.83653 25.5415 1.83653 23.0657V12.1716C1.83653 11.771 2.19236 11.4588 2.58836 11.5219C2.99125 11.5862 3.39643 11.6183 3.80162 11.6183C4.64068 11.6183 5.47515 11.4841 6.2706 11.22C6.57019 11.1213 6.8514 11.4094 6.74121 11.7044C5.60715 14.7278 6.25453 18.2666 8.68564 20.6977C11.1179 23.1299 14.6555 23.7762 17.6789 22.6421C17.975 22.5308 18.2631 22.8154 18.1633 23.1162C17.7753 24.2927 17.6674 25.5542 17.8614 26.7892C17.9234 27.1875 17.6158 27.5468 17.2117 27.5468V27.5479ZM27.5479 23.0657C27.5479 25.5404 25.5415 27.5479 23.0657 27.5479H20.5909C20.1812 27.5479 19.8288 27.2621 19.7404 26.8627C19.3088 24.9022 19.8908 22.804 21.3474 21.3485C22.804 19.8919 24.901 19.31 26.8615 19.7415C27.261 19.8299 27.5468 20.1823 27.5468 20.5909V23.0657H27.5479ZM27.5479 17.2117C27.5479 17.6146 27.1887 17.9234 26.7904 17.8614C25.5553 17.6686 24.2938 17.7753 23.1173 18.1633C22.8166 18.262 22.5331 17.975 22.6433 17.6789C23.7773 14.6567 23.1299 11.1179 20.6988 8.68564C18.2666 6.25338 14.729 5.60716 11.7056 6.74121C11.4094 6.85255 11.1213 6.56789 11.2212 6.26716C11.6092 5.09063 11.7171 3.82916 11.5231 2.5941C11.4611 2.1958 11.7687 1.83653 12.1727 1.83653H23.0668C25.5415 1.83653 27.5491 3.84294 27.5491 6.31881V17.2129L27.5479 17.2117Z" fill="currentcolor"></path>
                <path d="M0.54522 31.7134H2.08676V37.774H0.54522V31.7134Z" fill="currentcolor"></path>
                <path d="M3.48826 33.4111H4.86451V33.636C5.12392 33.4111 5.47056 33.2377 5.93773 33.2377C6.44851 33.2377 6.89847 33.4455 7.19346 33.8702C7.47927 33.5327 7.87757 33.2377 8.59611 33.2377C9.47075 33.2377 10.1973 33.861 10.1973 35.1248V37.774H8.8119V35.1856C8.8119 34.7965 8.61218 34.5279 8.22306 34.5279C7.78115 34.5279 7.60782 34.909 7.53895 35.0215V37.7751H6.15352V35.1868C6.15352 34.7976 5.95495 34.5291 5.56468 34.5291C5.13195 34.5291 4.95863 34.9101 4.86336 35.0571V37.7763H3.48711V33.4122L3.48826 33.4111Z" fill="currentcolor"></path>
                <path d="M11.2809 33.4111H12.6571V33.6533C12.951 33.3847 13.3321 33.2377 13.8692 33.2377C15.2627 33.2377 16.0685 34.3201 16.0685 35.5931C16.0685 36.866 15.2639 37.9484 13.8692 37.9484C13.3321 37.9484 12.951 37.8015 12.6571 37.5329V39.9055H11.2809V33.4111ZM12.6571 35.0479V36.1383C12.9165 36.4758 13.2368 36.6583 13.6707 36.6583C14.2767 36.6583 14.6486 36.2336 14.6486 35.5931C14.6486 34.9526 14.2767 34.5279 13.6707 34.5279C13.2288 34.5279 12.9005 34.7184 12.6571 35.0479Z" fill="currentcolor"></path>
                <path d="M16.5621 36.5446C16.5621 35.8778 17.0728 35.367 18.0255 35.2109L19.2549 35.0031C19.2457 34.6737 18.961 34.4235 18.5535 34.4235C18.1897 34.4235 17.9211 34.5968 17.7225 34.8826L16.7182 34.1204C17.1165 33.566 17.7833 33.2377 18.5972 33.2377C19.9826 33.2377 20.6231 34.0688 20.6231 35.0215V37.7751H19.2549V37.4457C19.0127 37.7143 18.5191 37.9048 18.06 37.9048C17.1773 37.9048 16.5621 37.394 16.5621 36.5458V36.5446ZM18.363 36.8385C18.8049 36.8385 19.0815 36.6307 19.2549 36.3713V35.9386L18.3974 36.0855C18.0428 36.1464 17.9211 36.2761 17.9211 36.4746C17.9211 36.6996 18.0944 36.8385 18.363 36.8385Z" fill="currentcolor"></path>
                <path d="M24.6577 36.2588L25.7309 37.0727C25.3234 37.6098 24.6749 37.9473 23.8691 37.9473C22.3276 37.9473 21.4449 36.8649 21.4449 35.5919C21.4449 34.319 22.3276 33.2366 23.8691 33.2366C24.6738 33.2366 25.3234 33.5741 25.7309 34.1112L24.6577 34.9251C24.5016 34.7001 24.233 34.5268 23.8531 34.5268C23.2906 34.5268 22.8751 34.9515 22.8751 35.5919C22.8751 36.2324 23.2906 36.6571 23.8795 36.6571C24.2261 36.6571 24.5028 36.4838 24.6589 36.2588H24.6577Z" fill="currentcolor"></path>
                <path d="M26.7961 34.6404H25.9823V33.4111H26.7961V32.3206H28.1815V33.4111H29.3764V34.6404H28.1815V36.0775C28.1815 36.4241 28.3629 36.6147 28.7268 36.6147C29.047 36.6147 29.2548 36.571 29.3845 36.5194V37.7051C29.1503 37.8096 28.8817 37.8612 28.4754 37.8612C27.2897 37.8612 26.7961 37.1507 26.7961 36.0775V34.6404Z" fill="currentcolor"></path>
                <path d="M4.59706 44.7379L5.81836 45.6642C5.25592 46.4091 4.35487 46.8935 3.24721 46.8935C1.31656 46.8935 0 45.422 0 43.6899C0 41.9578 1.31656 40.4863 3.24721 40.4863C4.35602 40.4863 5.25592 40.9707 5.81836 41.7076L4.59706 42.6431C4.31125 42.2276 3.85212 41.9337 3.24607 41.9337C2.27615 41.9337 1.58401 42.6787 1.58401 43.691C1.58401 44.7034 2.27615 45.4484 3.24607 45.4484C3.84294 45.4484 4.31125 45.1545 4.59706 44.7379Z" fill="currentcolor"></path>
                <path d="M12.7788 43.691C12.7788 45.4231 11.4622 46.8946 9.54077 46.8946C7.6193 46.8946 6.29356 45.4231 6.29356 43.691C6.29356 41.959 7.61012 40.4874 9.54077 40.4874C11.4714 40.4874 12.7788 41.959 12.7788 43.691ZM11.1948 43.691C11.1948 42.6695 10.5027 41.9337 9.54077 41.9337C8.57889 41.9337 7.87871 42.6787 7.87871 43.691C7.87871 44.7034 8.57086 45.4484 9.54077 45.4484C10.5107 45.4484 11.1948 44.7034 11.1948 43.691Z" fill="currentcolor"></path>
                <path d="M13.549 47.1403L14.9115 45.834C15.1032 45.6527 15.2845 45.4438 15.2845 45.189C15.2845 44.9341 15.1089 44.7757 14.8656 44.7757C14.5155 44.7757 14.3169 45.0191 14.1195 45.3474L13.3504 44.8722C13.6959 44.3063 14.2159 43.9275 14.962 43.9275C15.7081 43.9275 16.251 44.3797 16.251 45.0868C16.251 45.6297 15.8894 46.0533 15.5669 46.354L14.7244 47.1403H16.3531V48H13.5478V47.1403H13.549Z" fill="currentcolor"></path>
            </svg>
        </a>
        <div class="left_value">
            <div class="value">{{value | number: '0.1-1'}}</div>
            <div class="label">kg&nbsp;CO₂e</div>
        </div>
    </div>
    <div class="right">
        <div class="equal">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M10 4C10 3.44772 9.55228 3 9 3H3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H9C9.55228 5 10 4.55228 10 4Z" fill="currentcolor"></path>
                <path d="M10 8C10 7.44772 9.55228 7 9 7H3C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H9C9.55228 9 10 8.55228 10 8Z" fill="currentcolor"></path>
            </svg>
        </div>
        <div class="comp">
            <div class="emoji">
                <img src="https://impactco2.fr/icons/{{slug}}.svg" width="48" height="48" alt="">
            </div>
            <div class="etiquette">
                <div class="etiquette_value">{{(carbon_equivalents ? value / carbon_equivalents[slug].ecv : 0) | number: '0.1-1'}}</div>
                @if(carbon_equivalents[slug].type == "transport") {
                  <div class="etiquette_text">km via {{carbon_equivalents ? carbon_equivalents[slug].name : ''}}</div>
                } @else if (carbon_equivalents[slug].type == "heating") {
                  <div class="etiquette_text"> years of heating by {{carbon_equivalents ? carbon_equivalents[slug].name : ''}}</div>
                }
                @else {
                  <div class="etiquette_text"> units of {{carbon_equivalents ? carbon_equivalents[slug].name : ''}}</div>
                }
            </div>
        </div>
    </div>
</main>
