import { Routes } from '@angular/router';
import { StorageComponent } from './storage/storage.component';
import { ComputeComponent } from './compute/compute.component';
import { VmWizardComponent } from './vm-wizard/vm-wizard.component';
import { CartComponent } from './cart/cart.component';
import { AboutComponent } from './about/about.component';

export const routes: Routes = [
    { path: '', redirectTo: 'compute', pathMatch: 'full' },
    { path: 'compute', component: ComputeComponent },
    { path: 'storage', component: StorageComponent },
    { path: 'wizard', component: VmWizardComponent },
    { path: 'simulator', component: CartComponent },
    { path: 'about', component: AboutComponent },
];
