<div class="selectors" (click)="handleClick()">
    <div>
        <label for="deltatime">Time frame</label>
        <select name="deltatime" class="form-select" id="deltatime" [(ngModel)]="apiService.deltatime" (change)="onDeltaTimeChange()">
            <option value="1">Hourly</option>
            <option value="24">Daily</option>
            <option value="168">Weekly</option>
            <option value="720">Monthly</option>
            <option value="8760">Annually</option>
        </select>
    </div>
    @if (show_debug) {
      <div>
          <label for="chart_shown">Show chart</label>
          <input type="checkbox" id="chart_shown" name="chart_shown" [(ngModel)]="chart_shown">
      </div>
    }
    <div>
        <button class="btn btn-sm btn-danger" (click)="clearAllFilters()">Clear filters</button>
    </div>
    <div>
        <span style="font-size: 1.7em;">
          <b>ECLYO</b> : cloud pricing & environnemental impact solution
        </span>
    </div>

    <div style="position: absolute; right: 15px;">
      <a class="icon-link icon-link-hover" href="https://youtu.be/uK02i1I2aMg" target="_blank">
        <i class="bi bi-youtube"></i>
        Youtube tutorial
      </a>
    </div>
</div>
<div class="grid_div">
    <ag-grid-angular
        #agGrid
        [rowData]="formatted_storage_data"
        [columnDefs]="storageColDefs"
        [defaultColDef]="defaultColDef"
        [autoSizeStrategy]="autoSizeStrategy"
        (filterChanged)="filterChanged()"
        [pagination]=true
        class="ag-theme-quartz"
        style="height: 100%;">
    </ag-grid-angular>
</div>
@if (chart_shown) {
    <div class="selectors">
        <div>
            <label for="x_axis">X axis</label>
            <select name="x_axis" id="x_axis" [(ngModel)]="chart_axis_x" (change)="update_chart()">
                <option value="volume">Volume</option>
                <option value="iops">IOPS</option>
                <option value="iopsPerGB">IOPS per GB</option>
                <option value="maxIopsPerVolume">Max IOPS per Volume</option>
                <option value="maxThroughputPerVolume">Max Throughput per Volume</option>
                <option value="provisionedIops">Provisioned IOPS</option>
                <option value="throughputPerIO">Throughput per IO</option>
                <option value="volumeSizeMax">Volume Size Max</option>
                <option value="volumeSizeMin">Volume Size Min</option>
            </select>
        </div>
        <div>
            <label for="log_scale_x">Logarithmic X axis</label>
            <input type="checkbox" id="log_scale_x" name="log_scale_x" [(ngModel)]="chart_x_log" (change)="update_chart()">
        </div>
        <div>
            <label for="y_axis">Y axis</label>
            <select name="y_axis" id="y_axis" [(ngModel)]="chart_axis_y" (change)="update_chart()">
                <option value="volume">Volume</option>
                <option value="iops">IOPS</option>
                <option value="iopsPerGB">IOPS per GB</option>
                <option value="maxIopsPerVolume">Max IOPS per Volume</option>
                <option value="maxThroughputPerVolume">Max Throughput per Volume</option>
                <option value="provisionedIops">Provisioned IOPS</option>
                <option value="throughputPerIO">Throughput per IO</option>
                <option value="volumeSizeMax">Volume Size Max</option>
                <option value="volumeSizeMin">Volume Size Min</option>
            </select>
        </div>
        <div>
            <label for="log_scale_y">Logarithmic Y axis</label>
            <input type="checkbox" id="log_scale_y" name="log_scale_y" [(ngModel)]="chart_y_log" (change)="update_chart()">
        </div>
    </div>
    <div class="chart_div">
        <ag-charts-angular
            #agChart
            style="height: 100%;"
            [options]="chartOptions">
        </ag-charts-angular>
    </div>
}
