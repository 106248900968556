<!-- <pre>{{cartService.vmSelected | json}}</pre> -->
<!-- <pre>{{carbon_equivalents | json}}</pre> -->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cloud project assistant</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" style="position: relative; max-height: 500px;">
<!--    <app-vm-wizard></app-vm-wizard>-->
    <app-compute
      [hideHeader]="true"
      [maxHeight]="500"
    ></app-compute>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<div class="cart_body" *transloco="let t; prefix: 'simulator'">
    <h1>{{ t('title') }}</h1>
    <div class="selectors">
        <div>
            <label for="deltatime">Time frame</label>
            <select class="form-select" name="deltatime" id="deltatime" [(ngModel)]="apiService.deltatime">
                <option value="1">Hourly</option>
                <option value="24">Daily</option>
                <option value="168">Weekly</option>
                <option value="720">Monthly</option>
                <option value="8760">Annually</option>
            </select>
        </div>

        <div>
          <button class="btn btn-sm btn-primary" (click)="open(content)">Add a VM</button>
        </div>

        <div>
            <input #fileUpload class="file-input" type="file" (change)="onFileSelected($event)">
            <button class="btn btn-sm btn-primary" (click)="fileUpload.click()">Import Project as JSON</button>
        </div>
        <div>
            <button class="btn btn-sm btn-primary" (click)="export_cart_json()">Export Project as JSON</button>
        </div>
        <div>
            <button class="btn btn-sm btn-primary" (click)="excelService.exportExcel(cartService.vmSelected)">Export VMs as Excel</button>
        </div>
        <div>
            <button class="btn btn-sm btn-danger" (click)="cartService.clear_cart()">Clear simulator</button>
        </div>
    </div>
    <div class="cart_main">
        <div class="cart_list">
            @if (!cartService.vmSelected.length) {
                <p>No vm selected</p>
            }
            @else {
                @for (item of cartService.vmSelected; track item.id) {
                    <!-- <pre>{{item | json}}</pre> -->
                    <app-cart-item [item]="item"></app-cart-item>
                }
            }
        </div>
        <aside>
            <div>
                <h2>Total prices :</h2>
                <p>
                    On demand compute : {{format_currency(cartService.total_compute_od * apiService.deltatime)}}<br>
                    1 year reservation compute : {{format_currency(cartService.total_compute_1yr * apiService.deltatime)}}<br>
                    3 year reservation compute : {{format_currency(cartService.total_compute_3yr * apiService.deltatime)}}<br>
                    <br>
                    Storage : {{format_currency(cartService.total_storage_price * apiService.deltatime)}}<br>
                    <br>
                    On demand total : {{format_currency(cartService.total_od * apiService.deltatime)}}<br>
                    1 year reservation total : {{format_currency(cartService.total_1yr * apiService.deltatime)}}<br>
                    3 year reservation total : {{format_currency(cartService.total_3yr * apiService.deltatime)}}<br>
                </p>
                <br>
                <h2>Total consumption</h2>
                <p>
                    Consumption min : {{format_consumption(cartService.total_consumption_min * apiService.deltatime)}}<br>
                    Consumption max : {{format_consumption(cartService.total_consumption_max * apiService.deltatime)}}
                </p>
                <br>
                <h2>Total emmissions</h2>
                <p>
                    Emission min : {{format_co2(cartService.total_emission_min * apiService.deltatime)}}<br>
                    Emission max : {{format_co2(cartService.total_emission_max * apiService.deltatime)}}
                </p>
            </div>
            <app-carbon-widget [slug]="widget_slug" [value]="cartService.total_emission_max * apiService.deltatime"/>
        </aside>
    </div>
</div>
