import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EmissionData } from '../models/emission-data';
import { FormattedComputeData } from '../models/formatted-compute-data';
import { FormattedStorageData } from '../models/formatted-storage-data';
import { Fournisseur } from '../models/fournisseur-type.type';
import { PriceData } from '../models/prices-data';

import { CarbonEquivalents, FormattedCarbonEquivalents } from '../models/carbon-equivalents';
import { DeltaTime } from '../models/delta-time.type';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private baseUrl = 'https://cloudpricing.easyvirt.com';

    constructor(private http: HttpClient) { }

    getPrices(fournisseur: Fournisseur): Observable<PriceData> {
        return this.http.get<PriceData>(this.baseUrl + '/prices/' + fournisseur)
            .pipe(
                catchError(error => {
                    console.log(`Error fetching carbon data for ${fournisseur} : `, error.error);
                    return of({} as PriceData);
                })
            );
    }
    getCarbon(fournisseur: Fournisseur): Observable<EmissionData> {
        return this.http.get<EmissionData>(this.baseUrl + '/carbon/' + fournisseur)
            .pipe(
                catchError(error => {
                    console.log(`Error fetching carbon data for ${fournisseur} : `, error.error);
                    return of({} as EmissionData);
                })
            );
    }

    compute_data = new BehaviorSubject<FormattedComputeData[] | null>(null);
    storage_data = new BehaviorSubject<FormattedStorageData[] | null>(null);


    getCarbonEquivalents(theme: number, language='en'): Observable<CarbonEquivalents> {
        return this.http.get<CarbonEquivalents>(`https://impactco2.fr/api/v1/thematiques/ecv/${theme}?detail=0&language=${language}`)
            .pipe(
                catchError(error => {
                    console.log(`Error fetching carbon equivalents : `, error.error);
                    return of({} as CarbonEquivalents);
                })
            );
    }

    carbon_equivalents = new BehaviorSubject<FormattedCarbonEquivalents | null>(null);

    deltatime: DeltaTime = 1;
}
