import { Component, inject, TemplateRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { ExcelService } from '../services/excel.service';

import { FormattedCarbonEquivalents } from '../models/carbon-equivalents';

import { CartItemComponent } from '../cart-item/cart-item.component';
import { format_currency, format_consumption, format_co2 } from '../utils/formatter';
import { CarbonWidgetComponent } from '../carbon-widget/carbon-widget.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { VmWizardComponent } from "../vm-wizard/vm-wizard.component";
import { ComputeComponent } from "../compute/compute.component";

@Component({
    selector: 'app-cart',
    standalone: true,
    imports: [
      CommonModule,
      FormsModule,
      CartItemComponent,
      CarbonWidgetComponent,
      TranslocoDirective,
      VmWizardComponent,
      ComputeComponent
    ],
    templateUrl: './cart.component.html',
    styleUrl: './cart.component.scss'
})
export class CartComponent implements OnInit {
    carbon_equivalents!: FormattedCarbonEquivalents;
    format_currency = format_currency;
    format_consumption = format_consumption;
    format_co2 = format_co2;

    widget_slug = 'voiturethermique';

    private modalService = inject(NgbModal);

    constructor (
        public apiService: ApiService,
        public cartService: CartService,
        public excelService: ExcelService
    ) {}

    ngOnInit() {
        this.apiService.carbon_equivalents.subscribe(data => {
            if (!data) return;
            this.carbon_equivalents = data;
        })
    }

    open(content: TemplateRef<any>) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' , size: 'xl'}).result.then(
        // (result) => {
        //   this.closeResult = `Closed with: ${result}`;
        // },
        // (reason) => {
        //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        // },
      );
    }

    export_cart_json(): void {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + JSON.stringify(this.cartService.vmSelected, null, 4));
        element.setAttribute('download', 'exported_data.json');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = evt => {
                if (evt.target) {
                    // console.log(evt.target.result);
                    this.cartService.load_new_cart(evt.target.result);
                }
            }
            reader.onerror = function (evt) {
                console.log("Error reading file");
            }
        }
    }
}
