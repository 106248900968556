import { Injectable } from '@angular/core';
import { FormattedComputeData } from '../models/formatted-compute-data';
import { FormattedStorageData } from '../models/formatted-storage-data';

export interface StorageItem {
    id: string;
    storage_type: FormattedStorageData;
    volume: number;
}

export interface CartItem {
    id: string;
    vm: FormattedComputeData;
    storage: StorageItem[],
    storage_total_price: number;
    quantity: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {

    vmAmount: number = 0;
    total_od!: number;
    total_1yr!: number;
    total_3yr!: number;
    total_compute_od!: number;
    total_compute_1yr!: number;
    total_compute_3yr!: number;
    total_storage_price!: number;
    total_consumption_min!: number;
    total_consumption_max!: number;
    total_emission_min!: number;
    total_emission_max!: number;

    vmSelected: CartItem[] = [];

    constructor() {
        let stored_cart = localStorage.getItem('cart');
        if (stored_cart) {
            this.vmSelected = JSON.parse(stored_cart);
        }
        this.update_values();
    }

    update_values(): void {
        this.vmAmount = 0;
        this.total_compute_od = 0;
        this.total_compute_1yr = 0;
        this.total_compute_3yr = 0;
        this.total_storage_price = 0;
        this.total_consumption_min = 0;
        this.total_consumption_max = 0;
        this.total_emission_min = 0;
        this.total_emission_max = 0;

        for (let item of this.vmSelected) {
            this.vmAmount += item.quantity;
            this.total_compute_od += item.vm.od ? item.vm.od * item.quantity : 0;
            this.total_compute_1yr += item.vm['1yr'] ? item.vm['1yr'] * item.quantity : 0;
            this.total_compute_3yr += item.vm['3yr'] ? item.vm['3yr'] * item.quantity : 0;
            this.total_consumption_min += item.vm.consumption_min ? item.vm.consumption_min * item.quantity : 0;
            this.total_consumption_max += item.vm.consumption_max ? item.vm.consumption_max * item.quantity : 0;
            this.total_emission_min += item.vm.emission_min ? item.vm.emission_min * item.quantity : 0;
            this.total_emission_max += item.vm.emission_max ? item.vm.emission_max * item.quantity : 0;

            for (let storage of item.storage) {
                this.total_storage_price += storage.volume * storage.storage_type.volume * item.quantity;
            }
        }
        this.total_od = this.total_compute_od + this.total_storage_price;
        this.total_1yr = this.total_compute_1yr + this.total_storage_price;
        this.total_3yr = this.total_compute_3yr + this.total_storage_price;

        try {
            localStorage.setItem('cart', JSON.stringify(this.vmSelected));
        } catch (QuotaExceededError) {
            console.log("Couldn't store simulator content to the local storage")
        }
    }

    add_vm_to_cart(vm: FormattedComputeData, storage: StorageItem[] = []): string {
        let id = crypto.randomUUID();
        let newVm = {
          'id': id,
          'vm': vm,
          'quantity': 1,
          'storage': storage,
          'storage_total_price': 0,
        };
        // this.vmSelected.push(newVm);
        this.vmSelected.unshift(newVm);
        this.update_values();
        return id
    }

    update_cart_vm_quantity(id: string, quantity: any): void {
        quantity = parseInt(quantity);
        const index = this.vmSelected.findIndex(e => e.id == id);
        if (index < 0) return;
        if (quantity > 0) {
            this.vmSelected[index].quantity = quantity;
        } else {
            this.vmSelected = this.vmSelected.filter(e => e.id != id);
        }
        this.update_values();
    }

    delete_vm(id: string): void {
        this.vmSelected = this.vmSelected.filter(e => e.id != id);
        this.update_values();
    }

    add_storage_to_vm(id: string, storage_type: FormattedStorageData, volume: number): void {
        const index = this.vmSelected.findIndex(e => e.id == id);
        if (index < 0) return;
        this.vmSelected[index].storage.push({
            'id': crypto.randomUUID(),
            'storage_type': storage_type,
            'volume': volume,
        })
        this.update_storage_price(id);
        this.update_values();
    }

    update_disk_volume(vm_id: string, storage_id: string, volume: any): void {
        volume = parseInt(volume);
        const vm_index = this.vmSelected.findIndex(e => e.id == vm_id);
        if (vm_index < 0) return;
        const disk_index = this.vmSelected[vm_index].storage.findIndex(e => e.id == storage_id);
        if (disk_index < 0) return;
        this.vmSelected[vm_index].storage[disk_index].volume = volume;
        this.update_storage_price(vm_id);
        this.update_values();
    }

    delete_storage_from_vm(vm_id: string, storage_id: string): void {
        const index = this.vmSelected.findIndex(e => e.id == vm_id);
        if (index < 0) return;
        this.vmSelected[index].storage = this.vmSelected[index].storage.filter(e => e.id != storage_id);
        this.update_storage_price(vm_id);
        this.update_values();
    }

    update_storage_price(id: string): void {
        const index = this.vmSelected.findIndex(e => e.id == id);
        if (index < 0) return;
        this.vmSelected[index].storage_total_price = this.vmSelected[index].storage.reduce((total, storageItem) => {
            return total + (storageItem.storage_type.volume * storageItem.volume);
        }, 0);
    }

    clear_cart(): void {
        this.vmSelected = [];
        this.update_values();
    }

    load_new_cart(data: any): void {
        let new_cart: CartItem[];
        try {
            new_cart = JSON.parse(data);
        } catch (SyntaxError) {
            console.log('Error reading file');
            return;
        }
        this.vmSelected = new_cart;
        this.update_values();
    }
}
