<div class="card" style="position: relative; margin-bottom: 15px;">

  <div class="card-header">
    <h5 class="card-title"> {{ item.vm.provider }} -  {{ item.vm.instanceType }} </h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">({{ item.vm.region }})</h6>

    <div class="actions" style="position: absolute; right: 15px; top: 15px;">
      <span class="dropdown" style="floating: left;">
        <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false" data-bs-auto-close="outside">
          Quantity: {{ item.quantity }}
        </button>
        <form class="dropdown-menu p-4">
            <label>Quantity : </label>
            <input type="number" name="quantity" min=1 [value]="item.quantity"
                   (input)="cartService.update_cart_vm_quantity(item.id, $any($event.target).value)">
        </form>
      </span>
      <button class="btn btn-danger btn-sm" style="floating: left;" (click)="cartService.delete_vm(item.id)">
        Delete
      </button>
    </div>
  </div>

  <div class="card-body" style="position: relative;">
    <main>
      <div class="title" style="position: absolute; right: 7px; top: 7px; text-align: right;">
        <h3></h3>
        <div>
          <div>
            Total on demand price :
            <ng-template #odPriceContent>
              {{ item.quantity }} x ({{ (item.vm.od ? item.vm.od * apiService.deltatime : 0) }}
              + {{ format_currency(item.storage_total_price * apiService.deltatime) }})
            </ng-template>
            <span [ngbTooltip]="odPriceContent">
                    {{ format_currency(item.vm.od ? (item.vm.od + item.storage_total_price) * item.quantity * apiService.deltatime : 0) }}
                  </span>
          </div>
          @if (item.vm.consumption_max) {
            <div>
              Consommation :
              <ng-template #consumptionContent>
                {{ item.quantity }} x {{ format_consumption(item.vm.consumption_max * apiService.deltatime) }}
              </ng-template>
              <span [ngbTooltip]="consumptionContent">
                        {{ format_consumption(item.vm.consumption_max * item.quantity * apiService.deltatime) }}
                      </span>
            </div>
          } @else {
            <div class="warning">No consumption data</div>
          }
          @if (item.vm.emission_max) {
            <div>
              Emission :
              <ng-template #emissionContent>
                {{ item.quantity }} x {{ format_co2(item.vm.emission_max * apiService.deltatime) }}
              </ng-template>
              <span [ngbTooltip]="emissionContent">
                {{ format_co2(item.vm.emission_max * item.quantity * apiService.deltatime) }}
              </span>
            </div>
          } @else {
            <div class="warning">No emission data</div>
          }
        </div>
      </div>

      <h6 class="card-subtitle mb-2 text-body-secondary">Specifications</h6>

      <p>
        OS : {{ item.vm.os }}<br>
        vCPUs : {{ item.vm.vcpu }}<br>
        Memory : {{ format_storage(item.vm.memory / 1024) }}<br>
        Price on demand per unit : {{ format_currency(item.vm.od ? item.vm.od * apiService.deltatime : 0) }}
      </p>

      <div class="card" style="">
        <div class="card-header">
          Storage devices per VM
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" style="width: 100%;">
            <table class="table table-sm table-borderless" style="margin-bottom: 0px;">
              <tbody>
                @for (storage_item of item.storage; track storage_item.id) {
                  <tr>
                    <td>{{ storage_item.storage_type.name }}</td>
                    <td>
                      <input
                        type="number"
                        [value]="storage_item.volume"
                        [min]="storage_item.storage_type.volumeSizeMin"
                        [max]="storage_item.storage_type.volumeSizeMax"
                        (change)="cartService.update_disk_volume(item.id, storage_item.id, $any($event.target).value)"
                      > GiB
                    </td>
                    <td>Price
                      : {{ format_currency(storage_item.storage_type.volume * storage_item.volume * apiService.deltatime) }}
                    </td>
                    <td>
                      <button class="btn btn-danger btn-sm" (click)="cartService.delete_storage_from_vm(item.id, storage_item.id)">
                        Delete disk
                      </button>
                    </td>
                  </tr>
                }

                @if(item.storage.length == 0) {
                  <div class="alert alert-warning d-flex align-items-center" role="alert" style="margin-bottom: unset;">
                    <i class="bi bi-exclamation-triangle" style="background-color: unset;"></i>
                    <div style="background-color: unset;">
                      No storage device associated to this VM. Use the form below to add a storage device.
                    </div>
                  </div>
                }
              </tbody>
            </table>
          </li>
        </ul>
        <div class="card-footer">
          <form class="select_storage" #storageForm="ngForm">

            <div class="row">
              <div class="col col-md-auto">
                <label for="volume_input" style="margin-right: 5px;">Add a new device of </label>
              </div>
              <div class="col">
                <div class="input-group flex-nowrap input-group-sm" style="margin-bottom: 0px;">
<!--                  <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping">-->
                  <input type="number" class="form-control form-control-sm" name="volume_input" id="volume_input" placeholder="size"
                    [disabled]="storage_type_selected.volumeSizeMin == storage_type_selected.volumeSizeMax"
                    [(ngModel)]="storage_volume_input_value"
                  >
                  <span class="input-group-text" id="addon-wrapping">GiB</span>
                </div>
              </div>
              <div class="col col-md-auto">
                <select name="storage_type" [(ngModel)]="storage_type_selected" (change)="checkInputConstraints()">
                  @for (storage of available_storage; track storage) {
                    <option [ngValue]="storage">{{ storage.name }}</option>
                  }
                </select>
              </div>
              <div class="col">
                <button class="btn btn-primary btn-sm" (click)="add_storage(); resetForm();">Add device</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>

  </div>
</div>
