<header *transloco="let t; prefix: 'header'">
        <a href="https://www.easyvirt.com/">
            <img src="easyvirt_logo_blue.png" alt="Easy Comp">
        </a>
        <h1 style="margin-bottom: 0;">Eclyo</h1>
    <nav>
        <a routerLink="compute" routerLinkActive="active" [queryParams]="filterComputeService.getQueryParamsFromFilters()">{{t('compute')}}</a>
        <a routerLink="storage" routerLinkActive="active" [queryParams]="filterStorageService.getQueryParamsFromFilters()">{{t('storage')}}</a>
        <a routerLink="simulator" routerLinkActive="active">{{t('simulator')}}
            @if (cartService.vmAmount > 0) {
                <span>
                    {{cartService.vmAmount}} VMs
                </span>
            }
        </a>
        <a routerLink="about" routerLinkActive="active">{{t('about')}}</a>
    </nav>
    <select style="display: none;" (change)="changeLanguage($event)">
        @for (lang of availableLangs; track lang) {
            <option [value]="lang" [selected]="lang === activeLang">
                {{t('language.' + lang)}}
            </option>
        }
    </select>
</header>
<router-outlet></router-outlet>
