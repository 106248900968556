import { TitleCasePipe, formatCurrency, formatNumber, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
registerLocaleData(localeFr, 'fr');


import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ValueFormatterParams, ValueGetterParams, SizeColumnsToContentStrategy } from 'ag-grid-community';

import { AgChartsAngular } from 'ag-charts-angular';
import { AgCartesianChartOptions, AgCartesianSeriesTooltipRendererParams, AgScatterSeriesOptions } from 'ag-charts-community';


import { ApiService } from '../services/api.service';
import { StorageGridService } from '../services/storage-grid.service';

import { DeltaTime } from '../models/delta-time.type';
import { FormattedStorageData } from '../models/formatted-storage-data';
import { format_number, format_storage, format_throughput } from '../utils/formatter';
import {ProviderFilter} from "../utils/provider-filter";


@Component({
    selector: 'app-storage',
    standalone: true,
    imports: [
        TitleCasePipe,
        FormsModule,
        AgGridAngular,
        AgChartsAngular,
    ],
    templateUrl: './storage.component.html',
    styleUrl: './storage.component.scss'
})
export class StorageComponent implements OnInit, AfterViewInit {
    constructor(
        public apiService: ApiService,
        private filterStorageService: StorageGridService,
        private route: ActivatedRoute,
        private router: Router

    ) { }

    @ViewChild('agGrid', { static: true }) agGridStorage!: AgGridAngular;

    titleCasePipe = new TitleCasePipe()
    formatted_storage_data!: FormattedStorageData[];
    chart_data!: FormattedStorageData[];

    chartOptions: AgCartesianChartOptions = {
        series: [],
        axes: [],
    };

    chart_shown: boolean = false;
    chart_axis_x: string = 'volume';
    chart_axis_y: string = 'volumeSizeMax';
    chart_x_log: boolean = true;
    chart_y_log: boolean = true;

    delta_time: DeltaTime = 1; // default hourly
    show_debug: boolean = false;

    ngOnInit(): void {
        this.apiService.storage_data.subscribe(data => {
            if (!data) return;
            this.formatted_storage_data = data;
            this.chart_data = data;
            this.create_chart_options(this.formatted_storage_data);
            this.update_chart();
        })
    }

    ngAfterViewInit(): void {
        this.agGridStorage.api.setGridOption('rowData', this.formatted_storage_data);
        const filters = this.filterStorageService.getFiltersFromQueryParams(this.route.snapshot.queryParams);
        if (filters) this.agGridStorage.api.setFilterModel(filters);
    }



    filterChanged(): void {
        const filterModel = this.agGridStorage.api.getFilterModel();
        this.filterStorageService.persistFilters(filterModel);
        const params = this.filterStorageService.getQueryParamsFromFilters();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        })

        this.chart_data = [];
        this.agGridStorage.api.forEachNodeAfterFilter(node => {this.chart_data.push(node.data)});
        this.create_chart_options(this.chart_data);
        this.update_chart();
    }

    clickCount: number = 0;
    timeoutId: any;

    handleClick() {
      this.clickCount++;

      if (this.clickCount === 3) {
        this.show_debug = true;
        this.clickCount = 0;
        clearTimeout(this.timeoutId);
        return;
      }

      this.timeoutId = setTimeout(() => {
        this.clickCount = 0;
      }, 500);
    }

    clearAllFilters() {
        this.agGridStorage.api.setFilterModel(null);
    }


    onDeltaTimeChange() {
        this.agGridStorage.api.refreshCells()
        this.agGridStorage.api.onFilterChanged();
    }


    create_chart_options(data: FormattedStorageData[]): void {
        const options: AgCartesianChartOptions = {
            title: {text: "Memory vs Od"},
            series: [],
            axes: [
                {
                    type: "log",
                    position: "bottom",
                    title: {text: "Memory"},
                },
                {
                    type: "log",
                    position: "left",
                    title: {text: "Od"},
                },
            ],
        };
        options.series = [];
        const data_splitted = this.separerParFournisseur(data);

        for (const fournisseur in data_splitted) {
            options.series.push({
                type: "scatter",
                data: data_splitted[fournisseur],
                xKey: "volume",
                yKey: "volumeSizeMax",
                yName: `${fournisseur}`,
                tooltip: { renderer: this.renderer },
                marker: { size: 6 },
            })
        }
        this.chartOptions = options;
    }

    update_chart(): void {
        const options = { ...this.chartOptions};

        for (const series of options.series!) {
            (series as AgScatterSeriesOptions).xKey = this.chart_axis_x;
            (series as AgScatterSeriesOptions).yKey = this.chart_axis_y;
        }
        (options.title as any).text = `${this.titleCasePipe.transform(this.chart_axis_x)} vs ${this.titleCasePipe.transform(this.chart_axis_y)}`;
        (options.axes![0] as any).title.text = this.titleCasePipe.transform(this.chart_axis_x);
        (options.axes![1] as any).title.text = this.titleCasePipe.transform(this.chart_axis_y);
        (options.axes![0] as any).type = this.chart_x_log ? "log" : "number";
        (options.axes![1] as any).type = this.chart_y_log ? "log" : "number";

        this.chartOptions = options;
    }

    separerParFournisseur(items: FormattedStorageData[]) {
        const fournisseursMap: {[fournisseur: string] : [FormattedStorageData]} = {};
        items.forEach(item => {
            const fournisseur: string = item.provider;
            if (!fournisseursMap[fournisseur]) {
                fournisseursMap[fournisseur] = [item];
            } else {
                fournisseursMap[fournisseur].push(item);
            }
        });
        return fournisseursMap;
    }

    renderer({datum, xKey, yKey}: AgCartesianSeriesTooltipRendererParams) {
        return {
            title: `${datum.name} - ${datum.fournisseur}`,
            content: `Region : ${datum.region}<br>${xKey} : ${datum[xKey]}<br>${yKey} : ${datum[yKey]}`,
        };
    }


    numberFormatter = (params: ValueFormatterParams): string => {
        return params.value ? formatNumber(params.value, 'en-us', '1.0-10') : 'N/A';
    };

    currencyFormatter = (params: ValueFormatterParams): string => {
        return params.value ? formatCurrency(params.value, 'en-us', '$', '$', '1.0-10') : 'N/A';
    };

    public autoSizeStrategy: SizeColumnsToContentStrategy = { type: "fitCellContents" };

    storageColDefs: ColDef[] = [
        {
            field: "name",
            headerName: "Storage Name",
            initialPinned: "left",
            filter: "agTextColumnFilter"
        },
        {
            field: "provider",
            headerName: "Provider",
            // filter: "agTextColumnFilter"
            filter: ProviderFilter,
        },
        {
            field: "region",
            headerName: "Region",
            filter: "agTextColumnFilter"
        },
        {
            field: "volume",
            headerName: "Price",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => `${formatNumber(params.value, 'en-us', '1.2-6')} $ / GiB / h`,
            valueGetter: (params: ValueGetterParams): number => {
                return params.data.volume * this.apiService.deltatime;
            },
        },
        {
            field: "iops",
            headerName: "IOPS",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_number(params.value);
            },
            valueGetter: (params: ValueGetterParams): number => {
                return params.data.iops * this.apiService.deltatime;
            },
        },
        {
            field: "iopsPerGB",
            headerName: "IOPS per GB",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_number(params.value);
            },
        },
        {
            field: "maxIopsPerVolume",
            headerName: "Max IOPS per Volume",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_number(params.value);
            },
        },
        {
            field: "maxThroughputPerVolume",
            headerName: "Max Throughput per Volume",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_throughput(params.value);
            },
        },
        {
            field: "provisionedIops",
            headerName: "Provisioned IOPS",
            filter: "agNumberColumnFilter",
        },
        {
            field: "throughputPerIO",
            headerName: "Throughput per IO",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_throughput(params.value);
            },
        },
        {
            field: "volumeSizeMax",
            headerName: "Volume Size Max",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_storage(params.value);
            },
        },
        {
            field: "volumeSizeMin",
            headerName: "Volume Size Min",
            filter: "agNumberColumnFilter",
            valueFormatter: (params: ValueFormatterParams): string => {
                return format_storage(params.value);
            },
        },
    ];

    defaultColDef: ColDef = {
        filter: true,
        floatingFilter: true,
    };
}
