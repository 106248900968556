import { IDoesFilterPassParams, IFilterComp, IFilterParams } from '@ag-grid-community/core';

export class ProviderFilter implements IFilterComp {
  filterParams!: IFilterParams;
  gui!: HTMLDivElement;
  filterText: string = '';
  eFilterText!: HTMLInputElement;

  init(params: IFilterParams): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let providerRawValue = urlParams.get("provider");

    let providerValue = undefined;
    if (providerRawValue !== null) {
      let providerParamsRawValue = urlParams.get("provider");
      if (typeof(providerParamsRawValue) == "string") {
        let providerParamsValue = JSON.parse(decodeURIComponent(providerParamsRawValue));
        if (providerParamsValue !== undefined && providerParamsValue !== null) {
          // @ts-ignore
          providerValue = providerParamsValue.value;
        }
      }
    }

    console.log(providerValue);

    let amazonSelected = providerValue === "Amazon EC2" ? "selected": "";
    let googleSelected = providerValue === "google" ? "selected": "";
    let azureSelected = providerValue === "Azure" ? "selected": "";

    this.filterParams = params;
    this.gui = document.createElement('div');
    this.gui.innerHTML = `
        <div style="padding: 10px;">
            Select a cloud provider :
            <select id="filterText" type="select">
                <option ${amazonSelected} value="all">All</option>
                <option ${amazonSelected} value="Amazon EC2">Amazon EC2</option>
                <option ${azureSelected} value="Azure">Microsoft Azure</option>
                <option ${googleSelected} value="google">Google GCP</option>
            </select>
          </div>
    `;

    this.eFilterText = this.gui.querySelector('#filterText')!;

    if (providerValue === "all" ){
      this.filterText = "";
    } else {
      if (providerValue !== undefined) {
        this.filterText = providerValue;
      }
    }


    const listener = (event: any) => {
      if (event.target.value == "all") {
        this.filterText = "";
      } else {
        this.filterText = event.target.value;
      }
      params.filterChangedCallback();
    };
    this.eFilterText.addEventListener('changed', listener);
    this.eFilterText.addEventListener('paste', listener);
    this.eFilterText.addEventListener('input', listener);
  }

  isFilterActive(): boolean {
    return this.filterText != null && this.filterText !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const { node } = params;
    const value = this.filterParams.getValue(node).toString().toLowerCase();

    return this.filterText
      .toLowerCase()
      .split(' ')
      .every((filterWord) => value.indexOf(filterWord) >= 0);
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }

    return { value: this.filterText };
  }

  setModel(model: any): void {
    this.eFilterText.value = model == null ? '' : model.value;
  }

  getGui(): HTMLElement {
    return this.gui;
  }

  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent: ${message}`);
  }
}
