import { Injectable } from '@angular/core';
import {
    TextFilterModel,
    NumberFilterModel,
} from 'ag-grid-community';
import { ICombinedSimpleModel } from 'ag-grid-community';

interface ComputeGridFilters {
    region?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    country?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    continent?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    instanceType?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    provider?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    vcpu?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    memory?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    os?: TextFilterModel | ICombinedSimpleModel<TextFilterModel>;
    od?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    '3yr'?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    '1yr'?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    consumption_min?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    consumption_max?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    cpu_consumption_min?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    cpu_consumption_max?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    memory_consumption?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    storage_consumption?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    emission_min?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    emission_max?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    grey_emission?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    cpu_co2_min?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    cpu_co2_max?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    memory_co2?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
    storage_co2?: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>;
}

export interface ComputeGridFiltersParams {
    region?: string;
    country?: string;
    continent?: string;
    instanceType?: string;
    provider?: string;
    vcpu?: string;
    memory?: string;
    os?: string;
    od?: string;
    '3yr'?: string;
    '1yr'?: string;
    consumption_min?: string;
    consumption_max?: string;
    cpu_consumption_min?: string;
    cpu_consumption_max?: string;
    memory_consumption?: string;
    storage_consumption?: string;
    emission_min?: string;
    emission_max?: string;
    grey_emission?: string;
    cpu_co2_min?: string;
    cpu_co2_max?: string;
    memory_co2?: string;
    storage_co2?: string;
}


@Injectable({
    providedIn: 'root'
})
export class ComputeGridService {
    filters!: ComputeGridFilters;

    constructor() {}

    persistFilters(filters: ComputeGridFilters) {
        this.filters = filters;
    }

    getFiltersFromQueryParams(params: ComputeGridFiltersParams): ComputeGridFilters | null {
        if (!params || ! Object.keys(params).length) {
            return null;
        }
        return {
            region: params.region ? JSON.parse(decodeURIComponent(params.region)) : null,
            country: params.country ? JSON.parse(decodeURIComponent(params.country)) : null,
            continent: params.continent ? JSON.parse(decodeURIComponent(params.continent)) : null,
            instanceType: params.instanceType ? JSON.parse(decodeURIComponent(params.instanceType)) : null,
            provider: params.provider ? JSON.parse(decodeURIComponent(params.provider)) : null,
            vcpu: params.vcpu ? JSON.parse(decodeURIComponent(params.vcpu)) : null,
            memory: params.memory ? JSON.parse(decodeURIComponent(params.memory)) : null,
            os: params.os ? JSON.parse(decodeURIComponent(params.os)) : null,
            od: params.od ? JSON.parse(decodeURIComponent(params.od)) : null,
            '1yr': params['1yr'] ? JSON.parse(decodeURIComponent(params['1yr'])) : null,
            '3yr': params['3yr'] ? JSON.parse(decodeURIComponent(params['3yr'])) : null,
            consumption_min: params.consumption_min ? JSON.parse(decodeURIComponent(params.consumption_min)) : null,
            consumption_max: params.consumption_max ? JSON.parse(decodeURIComponent(params.consumption_max)) : null,
            cpu_consumption_min: params.cpu_consumption_min ? JSON.parse(decodeURIComponent(params.cpu_consumption_min)) : null,
            cpu_consumption_max: params.cpu_consumption_max ? JSON.parse(decodeURIComponent(params.cpu_consumption_max)) : null,
            memory_consumption: params.memory_consumption ? JSON.parse(decodeURIComponent(params.memory_consumption)) : null,
            storage_consumption: params.storage_consumption ? JSON.parse(decodeURIComponent(params.storage_consumption)) : null,
            emission_min: params.emission_min ? JSON.parse(decodeURIComponent(params.emission_min)) : null,
            emission_max: params.emission_max ? JSON.parse(decodeURIComponent(params.emission_max)) : null,
            grey_emission: params.grey_emission ? JSON.parse(decodeURIComponent(params.grey_emission)) : null,
            cpu_co2_min: params.cpu_co2_min ? JSON.parse(decodeURIComponent(params.cpu_co2_min)) : null,
            cpu_co2_max: params.cpu_co2_max ? JSON.parse(decodeURIComponent(params.cpu_co2_max)) : null,
            memory_co2: params.memory_co2 ? JSON.parse(decodeURIComponent(params.memory_co2)) : null,
            storage_co2: params.storage_co2 ? JSON.parse(decodeURIComponent(params.storage_co2)) : null,
        };
    }


    getQueryParamsFromFilters(): ComputeGridFiltersParams {
        if (!this.filters || !Object.keys(this.filters).length) {
            return {
                region: undefined,
                country: undefined,
                continent: undefined,
                instanceType: undefined,
                provider: undefined,
                vcpu: undefined,
                memory: undefined,
                os: undefined,
                od: undefined,
                '3yr': undefined,
                '1yr': undefined,
                consumption_min: undefined,
                consumption_max: undefined,
                cpu_consumption_min: undefined,
                cpu_consumption_max: undefined,
                memory_consumption: undefined,
                storage_consumption: undefined,
                emission_min: undefined,
                emission_max: undefined,
                grey_emission: undefined,
                cpu_co2_min: undefined,
                cpu_co2_max: undefined,
                memory_co2: undefined,
                storage_co2: undefined,
            };
        }

        return <ComputeGridFiltersParams> {
            region: encodeURIComponent(JSON.stringify(this.filters.region || null)),
            country: encodeURIComponent(JSON.stringify(this.filters.country || null)),
            continent: encodeURIComponent(JSON.stringify(this.filters.continent || null)),
            instanceType: encodeURIComponent(JSON.stringify(this.filters.instanceType || null)),
            provider: encodeURIComponent(JSON.stringify(this.filters.provider || null)),
            vcpu: encodeURIComponent(JSON.stringify(this.filters.vcpu || null)),
            memory: encodeURIComponent(JSON.stringify(this.filters.memory || null)),
            os: encodeURIComponent(JSON.stringify(this.filters.os || null)),
            od: encodeURIComponent(JSON.stringify(this.filters.od || null)),
            '1yr': encodeURIComponent(JSON.stringify(this.filters['1yr'] || null)),
            '3yr': encodeURIComponent(JSON.stringify(this.filters['3yr'] || null)),
            consumption_min: encodeURIComponent(JSON.stringify(this.filters.consumption_min || null)),
            consumption_max: encodeURIComponent(JSON.stringify(this.filters.consumption_max || null)),
            cpu_consumption_min: encodeURIComponent(JSON.stringify(this.filters.cpu_consumption_min || null)),
            cpu_consumption_max: encodeURIComponent(JSON.stringify(this.filters.cpu_consumption_max || null)),
            memory_consumption: encodeURIComponent(JSON.stringify(this.filters.memory_consumption || null)),
            storage_consumption: encodeURIComponent(JSON.stringify(this.filters.storage_consumption || null)),
            grey_emission: encodeURIComponent(JSON.stringify(this.filters.grey_emission || null)),
            cpu_co2_min: encodeURIComponent(JSON.stringify(this.filters.cpu_co2_min || null)),
            cpu_co2_max: encodeURIComponent(JSON.stringify(this.filters.cpu_co2_max || null)),
            memory_co2: encodeURIComponent(JSON.stringify(this.filters.memory_co2 || null)),
            storage_co2: encodeURIComponent(JSON.stringify(this.filters.storage_co2 || null)),
        };
    }
}
