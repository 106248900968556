import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';

import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoService } from "@jsverse/transloco";

import { Observable, forkJoin } from 'rxjs';

import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { ComputeGridService } from '../services/compute-grid.service';
import { StorageGridService } from '../services/storage-grid.service';

import { Fournisseur } from '../models/fournisseur-type.type';
import { PriceData } from '../models/prices-data';
import { EmissionData } from '../models/emission-data';
import { FormattedComputeData } from '../models/formatted-compute-data';
import { FormattedStorageData } from '../models/formatted-storage-data';


@Component({
    selector: 'app-api-viewer',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        TitleCasePipe,
        TranslocoDirective
    ],
    templateUrl: './api-viewer.component.html',
    styleUrl: './api-viewer.component.scss'
})
export class ApiViewerComponent implements OnInit {
    availableLangs: string[] = [];
    activeLang: string = '';

    constructor(
        private apiService: ApiService,
        public cartService: CartService,
        public filterComputeService: ComputeGridService,
        public filterStorageService: StorageGridService,
        public translocoService: TranslocoService
    ) {
        this.availableLangs = this.translocoService.getAvailableLangs() as string[];
        this.activeLang = this.translocoService.getActiveLang();
    }

    fournisseurs: Fournisseur[] = ['aws', 'az', 'gcp', 'ovh'];

    formatted_compute_data: FormattedComputeData[] = [];
    formatted_storage_data: FormattedStorageData[] = [];

    ngOnInit(): void {
        const apiCalls: Observable<any>[] = [];
        this.fournisseurs.forEach(fournisseur => {
            apiCalls.push(this.apiService.getPrices(fournisseur));
            apiCalls.push(this.apiService.getCarbon(fournisseur));
        });

        forkJoin(apiCalls).subscribe({
            next: (result): void => {
                for (let i = 0; i < this.fournisseurs.length; i++) {
                    let price_result: PriceData = result[i*2];
                    let carbon_result: EmissionData = result[i*2+1];

                    let new_compute_lines = this.formatComputeData(price_result, carbon_result);
                    for (let i = 0; i < new_compute_lines.length; i++){
                        this.formatted_compute_data.push(new_compute_lines[i])
                    }
                    let new_storage_lines = this.formatStorageData(price_result);
                    for (let i = 0; i < new_storage_lines.length; i++){
                        this.formatted_storage_data.push(new_storage_lines[i])
                    }
                }
                console.log(`Displaying ${this.formatted_compute_data.length} rows of compute data`);
                console.log(`Displaying ${this.formatted_storage_data.length} rows of storage data`);
                this.apiService.compute_data.next(this.formatted_compute_data);
                this.apiService.storage_data.next(this.formatted_storage_data);
            },
            error: (error): void => {
                console.error('Unexpected error', error);
            }
        });
        let carbon_categories_requests = [4, 8, 2, 9].map(category => {return this.apiService.getCarbonEquivalents(category)})
        forkJoin(carbon_categories_requests).subscribe({
            next: (result): void => {
                let output: any = {};

                for (let i = 0; i < carbon_categories_requests.length; i++) {
                    let type = "unit";
                    let keys = result[i].data.map(o => o.slug);
                    if (keys.indexOf("tgv") !== -1) {
                      type = "transport";
                    }
                    if (keys.indexOf("pompeachaleur") !== -1) {
                      type = "heating";
                    }
                    result[i].data.forEach(e => {
                        output[e.slug] = {
                            'name': e.name,
                            'ecv': e.ecv,
                            'type': type
                        }
                    })
                }

                this.apiService.carbon_equivalents.next(output);
            },
            error: (error): void => {
                console.error('Unexpected error', error);
            }
        })
    }



    formatComputeData(price_data: PriceData, emission_data: EmissionData): FormattedComputeData[] {
        const formattedComputeData: FormattedComputeData[] = [];
        for (const region in price_data.computePrices) {
            for (const instanceType in price_data.computePrices[region]) {
                let emissionData;
                if (!emission_data[region] || !emission_data[region].instance_types[instanceType]) {
                    emissionData = null;
                } else {
                    emissionData = emission_data[region].instance_types[instanceType].cpu_architectures[emission_data[region].instance_types[instanceType].lowest_cpu_architecture];
                }
                for (const os in price_data.computePrices[region][instanceType]) {
                    let prices = price_data.computePrices[region][instanceType][os];
                    let new_row = {
                        'region': region,
                        'country': price_data.regionsMetadata[region].country,
                        'continent': price_data.regionsMetadata[region].continent,
                        'instanceType': instanceType,
                        'provider': price_data.name,
                        'vcpu': price_data.computeTypes[instanceType].vcpu,
                        'memory': price_data.computeTypes[instanceType].memory,
                        'os': os,
                        'od': prices['od'] ? prices['od']/(365*24) : null,
                        '1yr': prices['1yr'] ? prices['1yr']/(365*24) : null,
                        '3yr': prices['3yr'] ? prices['3yr']/(365*24) : null,
                        'consumption_min': emissionData ? emissionData.cpu_consumption_min + emissionData.memory_consumption + emissionData.storage_consumption : null,
                        'consumption_max': emissionData ? emissionData.cpu_consumption_max + emissionData.memory_consumption + emissionData.storage_consumption : null,
                        'cpu_consumption_min': emissionData ? emissionData.cpu_consumption_min : null,
                        'cpu_consumption_max': emissionData ? emissionData.cpu_consumption_max : null,
                        'memory_consumption': emissionData ? emissionData.memory_consumption : null,
                        'storage_consumption': emissionData ? emissionData.storage_consumption : null,
                        'emission_min': emissionData ? emissionData.grey_emission + emissionData.cpu_co2_min + emissionData.memory_co2 + emissionData.storage_co2 : null,
                        'emission_max': emissionData ? emissionData.grey_emission + emissionData.cpu_co2_max + emissionData.memory_co2 + emissionData.storage_co2 : null,
                        'grey_emission': emissionData ? emissionData.grey_emission : null,
                        'cpu_co2_min': emissionData ? emissionData.cpu_co2_min : null,
                        'cpu_co2_max': emissionData ? emissionData.cpu_co2_max : null,
                        'memory_co2': emissionData ? emissionData.memory_co2 : null,
                        'storage_co2': emissionData ? emissionData.storage_co2 : null,
                    }
                    formattedComputeData.push(new_row);
                }
                // break;
            }
            // break;
        }
        return formattedComputeData;
    }

    formatStorageData(price_data: PriceData): FormattedStorageData[] {
        const formattedStorageData: FormattedStorageData[] = [];
        for (const region in price_data.storagePrices) {
            for (const instanceType in price_data.storagePrices[region]) {
                const new_row = {
                    'region': region,
                    'provider': price_data.name,
                    'name': price_data.storageTypes[instanceType].name,
                    'volume': price_data.storagePrices[region][instanceType].volume/(30*24),
                    'iops': price_data.storagePrices[region][instanceType].iops,
                    'iopsPerGB': price_data.storageTypes[instanceType].iopsPerGB,
                    'maxIopsPerVolume': price_data.storageTypes[instanceType].maxIopsPerVolume,
                    'maxThroughputPerVolume': price_data.storageTypes[instanceType].maxThroughputPerVolume,
                    'provisionedIops': price_data.storageTypes[instanceType].provisionedIops,
                    'throughputPerIO': price_data.storageTypes[instanceType].throughputPerIO,
                    'volumeSizeMax': price_data.storageTypes[instanceType].volumeSizeMax,
                    'volumeSizeMin': price_data.storageTypes[instanceType].volumeSizeMin,
                };
                formattedStorageData.push(new_row);
            }
        }
        return formattedStorageData;
    }

    changeLanguage(event: Event) {
        const lang = (event.target as HTMLSelectElement).value;
        this.translocoService.setActiveLang(lang);
    }
}
